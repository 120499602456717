import {
  Box,
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip,
} from '@chakra-ui/react';
import {useState} from 'react';

export type SliderProps = {
  label: string;
  isDisabled: boolean;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: (v: number) => number;
};

export default function Slider(props: SliderProps): JSX.Element {
  const {label, isDisabled, defaultValue = 1, min = 1, max = 100, step, onChange} = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const [value, setValue] = useState(1);

  return (
    <div>
      <label htmlFor='scale-input'>
        {label} ({value}):
      </label>
      <ChakraSlider
        id='scale-input'
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step}
        onChange={v => setValue(onChange(v))}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <SliderTrack bg='red.100'>
          <Box position='relative' right={10} />
          <SliderFilledTrack bg='tomato' />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg='teal.500'
          color='white'
          placement='top'
          isOpen={showTooltip}
          label={value}
        >
          <SliderThumb />
        </Tooltip>
        <SliderThumb boxSize={6} />
      </ChakraSlider>
    </div>
  );
}
