import Layout from 'components/layout';
import SEO from 'components/seo';

import bannerBgSVG from 'assets/images/gallery/smaller_shutterstock_1389573218.svg';

import CropperAlt from 'components/Tools/Cropper/CropperAlt';
import useIsClient from 'hooks/useIsClient';
import styled from 'styled-components';

const BackGround = styled.div`
  background: url(${bannerBgSVG}) no-repeat center top / cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
`;

export default function CropperPage(): JSX.Element | null {
  const {isClient} = useIsClient();

  if (!isClient) {
    return null;
  }

  return (
    <>
      <BackGround className='BackGround_BackGround' />
      <Layout>
        <>
          <SEO title='Free Image cropper' />
          {/* <Cropper /> */}
          <CropperAlt src='https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg' />
        </>
      </Layout>
    </>
  );
}
