/* eslint-disable @typescript-eslint/no-explicit-any */
import {DependencyList, useEffect} from 'react';

export function useDebounceEffect(
  fn: (params: any) => void,
  waitTime: number,
  deps?: DependencyList,
): void {
  useEffect(() => {
    const t = setTimeout(() => {
      if (deps) {
        fn([undefined, ...deps]);
      }
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
