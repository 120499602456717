import {Checkbox, FormControl, FormLabel, HStack, Input} from '@chakra-ui/react';
import {useDebounceEffect} from 'hooks/useDebounceEffect';
import {useRef, useState} from 'react';
import ReactCrop, {centerCrop, Crop, makeAspectCrop, PixelCrop} from 'react-image-crop';

import defaultImage from 'assets/images/blog/1.png';

import 'react-image-crop/dist/ReactCrop.css';
import Slider from '../../Slider/Slider';
import {canvasPreview} from './CanvasPreview';

type CropperAltProps = {
  src: string;
};

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export default function CropperAlt(props: CropperAltProps): JSX.Element {
  const [imgSrc, setImgSrc] = useState(defaultImage);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState<number>(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const {width, height} = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const {width, height} = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }

  return (
    <HStack
      gap={'2rem'}
      justifyContent='center'
      margin={'2rem'}
      padding={'2rem'}
      backgroundColor='rgba(255,255,255,.5)'
    >
      <FormControl as='fieldset' style={{maxWidth: '30%', minWidth: '30%'}}>
        <FormLabel as='legend'>Einstellungen</FormLabel>
        <Input
          accept='image/*'
          placeholder='Select Date and Time'
          size='md'
          type='file'
          onChange={onSelectFile}
        />
        <div>
          <Slider
            isDisabled={!imgSrc}
            label='Scale'
            defaultValue={1}
            min={1}
            max={10}
            step={0.1}
            onChange={(v): number => {
              const n = Number(v);
              setScale(n);
              return n;
            }}
          />
          <Slider
            isDisabled={!imgSrc}
            label='Rotation'
            defaultValue={0}
            min={0}
            max={180}
            step={5}
            onChange={(v): number => {
              const n = Number(Math.min(180, Math.max(-180, Number(v))));
              setRotate(n);
              return n;
            }}
          />
        </div>
        <div>
          <Checkbox onClick={handleToggleAspectClick} colorScheme='green' isDisabled={!imgSrc}>
            Toggle aspect {aspect ? 'off' : 'on'}
          </Checkbox>
        </div>
      </FormControl>
      {Boolean(imgSrc) && (
        <ReactCrop
          style={{maxWidth: '30%', minWidth: '30%', maxHeight: '500px'}}
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={c => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt='Crop me'
            src={imgSrc}
            style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <div style={{maxWidth: '30%', minWidth: '30%'}}>
        {Boolean(completedCrop) && completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
    </HStack>
  );
}
